import {
  IChatDetails,
  IChatMessageResponse,
  IRecentChat,
  ISendMessageError,
  IUnlockImageResponse,
  IUnlockMessagesResponse,
  IUnlockCharactersResponse,
  IAudioMessageQuotaResponse,
  IUnlockImageGenResponse
} from '@/models/chat';
import { ICharacterCreationResponse } from '@/models/characters';
import { IImageGenerationResponse } from '@/models/image';
import api, { callApi } from './config';
import { IError } from '@/models/common';
import { ICharacter, ICharacterAlbum } from '@/models/characters';
import { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';

const charactersApi = {
  getActiveChars() {
    const url = '/roleplay/active_characters';
    return callApi<ICharacter[], IError>(api.get(url));
  },
  getChatDetails(characterId: number, lastMessageId?: number): Promise<IChatDetails> {
    let url = `/roleplay/character/${characterId}/chat`;

    if (lastMessageId) {
      url += `?last_message_id=${lastMessageId}`;
    }

    return api.get<never, IChatDetails>(url);
  },
  async sendMessageToChar(character_id: number, user_input: string) {
    const url = `/roleplay/character/${character_id}/message`;
    return callApi<IChatMessageResponse[], ISendMessageError>(api.post(url, { character_id, user_input }));
  },
  async requestImage(character_id: number, user_input: string) {
    const url = `/roleplay/character/${character_id}/request_image`;
    return callApi<IChatMessageResponse[], ISendMessageError>(api.post(url, { character_id, user_input }));
  },
  async getUserRecentChats() {
    const url = '/roleplay/inbox';
    return callApi<IRecentChat[], any>(api.get(url));
  },
  async resetChat(id: number) {
    const url = `/roleplay/character/${id}/reset`;
    return callApi<IChatMessageResponse[], any>(api.post(url));
  },
  async deleteChat(id: number) {
    const url = `/roleplay/inbox/delete/${id}`;
    return callApi<{}, any>(api.post(url));
  },
  async unlockImage(characterId: number, imageId: number) {
    const url = `/roleplay/character/${characterId}/unlock_image/${imageId}`;
    return callApi<IUnlockImageResponse, IError>(api.post(url));
  },
  async unlockMessages(characterId: number) {
    const url = `/roleplay/unlock_messages?character=${characterId}`;
    return callApi<IUnlockMessagesResponse, IError>(api.post(url));
  },
  async unlockAudioMessages() {
    const url = `/roleplay/unlock_audio_messages`;
    return callApi<IUnlockImageGenResponse, IError>(api.post(url));
  },
  async unlockImageGen() {
    const url = `/image/unlock_image_gen`;
    return callApi<IUnlockMessagesResponse, IError>(api.post(url));
  },
  async unlockCharacters() {
    const url = `/roleplay/unlock_characters`;
    return callApi<IUnlockCharactersResponse, IError>(api.post(url));
  },
  async getAlbums(characterId: number) {
    const url = `/roleplay/character/${characterId}/albums`;
    return callApi<ICharacterAlbum[], IError>(api.get(url));
  },
  async regenerateLastMessage(characterId: number) {
    const url = `/roleplay/character/${characterId}/regen_last_message`;

    // TODO ensure types
    return callApi<
      {
        gem_cost?: number;
        image_data?: string;
        image_id?: number;
        image_locked?: boolean;
        prompt?: string;
        text?: string;
      },
      IError
    >(api.post(url));
  },

  getTemplateImages(count: number, style: string) {
    const url = `/roleplay/template_images?count=${count}&style=${style}`;
    return callApi<{ images: { url: string; key: string; style: string, image_request_id: string }[] }, IError>(api.get(url));
  },

  async createCharacter(choices: Record<string, any>, experiment_variant?: string | null) {
    const url = '/roleplay/create_character';
    return callApi<ICharacterCreationResponse, IError>(api.post(url, { ...choices, experiment_variant }));
  },

  async renameCharacter(characterId: number, name: string) {
    const url = `/roleplay/character/${characterId}/rename`;
    return callApi<ICharacter, IError>(api.post(url, { name }));
  },

  async generateNewCharacterImage(choices: Record<string, any>) {
    const url = '/image/generate_new_character_image';
    return callApi<IImageGenerationResponse, IError>(api.post(url, { ...choices }));
  },

  async generateUserImage(characterId: number, userPrompt: string) {
    const url = '/image/generate_user_image';
    return callApi<IImageGenerationResponse, IError>(api.post(url, { character_id: characterId, user_prompt: userPrompt }));
  },

  async pollImageGeneration(image_request_id: number) {
    const url = `/image/poll_generation/${image_request_id}`;
    return callApi<IImageGenerationResponse, IError>(api.post(url));
  },

  // DEV method for burning your messages quota
  async burn() {
    const url = '/roleplay/burn_messages';
    alert('Burning your quota. You will be informed when ready.');
    const res = await callApi<IUnlockMessagesResponse, IError>(api.get(url));

    if (!(res instanceof AxiosError)) {
      alert('Your messages qouta has been reset');
    }
  },
  async generateAudio(messageId: number, characterId: number, messageText: string): Promise<ArrayBuffer | IAudioMessageQuotaResponse> {
    const url = `/roleplay/generate_audio`;
    const payload = {
      message_id: messageId,
      character_id: characterId,
      message_text: messageText
    };

    try {
      const res = await api.post<ArrayBuffer>(url, payload, { 
        responseType: 'arraybuffer',
        headers: { 'Accept': 'application/json, application/octet-stream' }
      });

      // Check the size of res.data
      // If the size is suspiciously small, it might be a JSON response
      // This part is a bit hacky, wan't able to find a cleaner way to detect the response type
      // given some api custom middleware in config.ts
      const arrayBufferData = res instanceof ArrayBuffer ? res : res.data;
      if (arrayBufferData instanceof ArrayBuffer) {
        const dataSize = arrayBufferData.byteLength;
        if (dataSize < 1024) {
          const text = new TextDecoder().decode(arrayBufferData);
          try {
            const jsonResponse = JSON.parse(text);
            if ('audio_locked' in jsonResponse) {
              return jsonResponse as IAudioMessageQuotaResponse;
            }
          } catch (e) {
            // Not JSON, continue treating as ArrayBuffer
          }      
        }
        return arrayBufferData;
      } 
      
      return res as unknown as ArrayBuffer;

    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  },
  async getAudioSample(characterId: number): Promise<ArrayBuffer> {
    const url = `/roleplay/get_audio_sample`;
    const payload = {
      character_id: characterId,
    };

    try {
      const res = await api.post<ArrayBuffer>(url, payload, { 
        responseType: 'arraybuffer',
        headers: { 'Accept': 'application/json, application/octet-stream' }
      });

      return res instanceof ArrayBuffer ? res : res.data;
    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  },  
  async getVoiceSampleUrl(voiceId: string, personality: string): Promise<string> {
    const url = `/roleplay/voice_samples?voice_id=${voiceId}&personality=${personality}`;
    try {
      console.log('Fetching voice sample URL:', url);
      const response = await api.get<{ url: string }>(url);
      
      console.log('Full API response:', response);
      console.log('Response type:', typeof response);
      console.log('Response keys:', Object.keys(response));
      
      if (!response || typeof response !== 'object') {
        throw new Error('Invalid API response');
      }
      
      if (!('url' in response)) {
        throw new Error('Response is missing "url" property');
      }
      
      if (typeof response.url !== 'string') {
        throw new Error(`Unexpected url type: ${typeof response.url}`);
      }
      
      console.log('Successfully retrieved voice sample URL:', response.url);
      return response.url;
    } catch (error) {
      console.error('Error in getVoiceSampleUrl:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
      throw error;
    }
  },

  // Add a static method for SSG
  getActiveCharsStatic: async () => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/roleplay/active_characters`);
    return response.json();
  },

  getCharactersByCards(characterCards: string[]) {
    const url = '/roleplay/get_characters_by_cards';
    return callApi<ICharacter[], IError>(api.post(url, characterCards));
  },
};

export default charactersApi;
